import React from "react";

import LenderLogoSlider from "components/lender_logos_slider";
import TeamShowcaseSlider2 from "components/team_showcase_slider_2";
import CTABar2 from "components/cta_bar_2";
import ScrollAnimation from "react-animate-on-scroll";
import KG2Analytics from "components/kg2_analytics";
import "./styles.css";
import LargeHeroBanner from "components/large_hero_banner";

const AboutPage = () => {
  return (
    <>
      {/* KG2 Analytics */}
      <KG2Analytics />

      {/* Header Space */}
      <div id="header-area-space" className="slider-area bg-light-primary" />

      <LargeHeroBanner bgImage={"/img/banner/about-bg.jpg"}>
        <h2>
          <strong>About Us</strong>
        </h2>
        <br />
        <h2>Finstead Risk Solutions is</h2>
        <h2>
          the <strong>partner of choice</strong> for
        </h2>
        <h2>Australian businesses</h2>
        <br />
        {/* <a
          href="/contact"
          className="btn-fill color-light radius-4 btn-icon size-sm icon-right mt-2"
        >
          Call Now
          <i className="fas fa-angle-right" />
        </a> */}
      </LargeHeroBanner>

      <CTABar2 />

      <section className="section-space-default-less38 bg-light-primary100">
        <div className="container">
          <div className="section-heading text-left heading-dark heading-layout1">
            <ScrollAnimation animateIn="fadeInUp" animateOnce>
              <h3
                style={{
                  fontWeight: "300",
                  textTransform: "none",
                  marginBotton: "17.6px",
                }}
                className=""
              >
                Finstead Risk Solutions is an independent, Australian owned
                insurance broker and risk advisor, with correspondent brokers in
                key global insurance markets. Established in 2021, the business
                is also part of the Finstead Group of companies.
              </h3>
            </ScrollAnimation>
            <br />
            <div style={{ fontWeight: "300" }}>
              <ScrollAnimation animateIn="fadeInUp" animateOnce>
                <p>
                  Finstead Risk Solutions combines experience and innovation to
                  find the most effective solution for your business. Led by
                  professional veterans to the sector, our experience, knowledge
                  of the industry, and commercial network is difficult to match.
                  We work closely with our clients, forming partnerships built
                  on trust and guaranteed results.
                </p>
              </ScrollAnimation>
              <br />
              <ScrollAnimation animateIn="fadeInUp" animateOnce>
                <p
                  style={{ border: "1px solid #D9963D" }}
                  className="p-3 pt-5 pb-5"
                >
                  Our experienced team of insurance and risk professionals work
                  to quickly and effectively address risk in your business,
                  establishing a platform for mutual success.
                </p>
              </ScrollAnimation>
              <br />
              <ScrollAnimation animateIn="fadeInUp" animateOnce>
                <p>
                  We work with a range of small to medium business and
                  corporates, through to industry associations; and are
                  dedicated to helping maintain and improve your business,
                  regardless of the current financial environment.
                </p>
              </ScrollAnimation>
              <br />
              <ScrollAnimation animateIn="fadeInUp" animateOnce>
                <p>
                  Underpinning our sector leadership is our range of technology
                  offerings, tailored to each client's specific needs. From
                  white label quote/bind/pay solutions, to more advanced direct
                  API integrations, we have the capability and experience to
                  deliver bespoke customised technology solutions for our
                  clients.
                </p>
              </ScrollAnimation>
              <br />
              <ScrollAnimation animateIn="fadeInUp" animateOnce>
                <p>
                  Put simply our technology suite of products enable the
                  generation, offering and sale of insurance quotes with minimal
                  data collection, compliance and questioning thereby making all
                  insurance easy and accessible to both your team and your
                  customers.
                </p>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </section>

      <TeamShowcaseSlider2 />
    </>
  );
};

export default AboutPage;
