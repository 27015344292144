import React from "react";
import Layout from "components/layout";
import AboutPage from "./paths/about_page";
import FsAnalytics from "components/fs_analytics";

const About = () => {
  return (
    <Layout>
      {/* Finstead Analytics */}
      <FsAnalytics />
      <AboutPage />
    </Layout>
  );
};
export default About;
