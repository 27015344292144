import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import "./index.css";

const TeamShowcaseSlider2 = () => {
  return (
    <>
      <ScrollAnimation animateIn="slideInUp" animateOnce>
        <div className="team-showcase-slider-circle-top" />
        <img
          src="/img/banner/page-sep-1.png"
          style={{ position: "relative" }}
        ></img>
      </ScrollAnimation>
      <section
        className="section-space-default-less30 bg-light-accent100"
        style={{ marginBottom: "0px" }}
      >
        <div className="container">
          <div className="section-heading text-center heading-dark heading-layout1">
            <ScrollAnimation animateIn="fadeInRight" animateOnce>
              <h2 className="fs-section-header">Our Founders</h2>
            </ScrollAnimation>
            <ScrollAnimation
              animateIn="fadeInLeft"
              animateOnce
            ></ScrollAnimation>
          </div>

          <div>
            <h3 style={{ marginBottom: "0px", paddingBottom: "0px" }}>
              James Sinclair
            </h3>
            <span>CEO / Director</span>
            <br />
            <br />

            <p>
              Co-founding Finstead Risk Solutions in 2021, James' previous role
              was CEO of Mega Capital from July 2019. Prior to this, James was a
              shareholder and member of the leadership group at InterRISK. He
              has specialised in professional liability broking since the early
              1990s. Commencing his insurance career in the late 1980s as a
              general insurance broker, James has advised clients and managed
              teams in Victoria, WA and NSW.{" "}
            </p>

            <p>
              He has a long history in executive management in corporate broking
              firms, whilst developing programmes and advocating on behalf of
              clients in key insurance markets, including Australia, the United
              States and London.{" "}
            </p>

            <p>
              James has considerable experience arranging insurance solutions
              for ASX100 and other large corporate entities, professional firms,
              group associations and SME's. His specialisation includes
              Professional Indemnity, Directors' &amp; Officers' Liability,
              Initial Public Offerings, Investment Managers, Warranty &amp;
              Indemnity, Crime, Information Technology, Cyber and Malpractice.
            </p>
          </div>
          <br />
          {/* divider pill */}
          <div className="w-100">
            <div
              style={{
                width: "30px",
                height: "5px",
                backgroundColor: "#e6e6e6",
                borderRadius: "30px",
                margin: "auto auto",
              }}
            />
          </div>
          <br />
          <div>
            <h3 style={{ marginBottom: "0px", paddingBottom: "0px" }}>
              Dennis Guy
            </h3>
            <span>Director</span>
            <br />
            <br />

            <p>
              Dennis has worked in leading roles for insurance broking
              organisations at a global scale for more than 40 years. Working in
              Europe, USA, Australia and Asia, Dennis has demonstrated success
              in the management and development of insurance broking.{" "}
            </p>

            <p>
              Dennis was previously Executive Chairman of InterRISK Australia
              Pty Ltd, a major corporate insurance broking company which he
              established in 2004. He joined Jardine Matheson in Hong Kong in
              1976 and remained for 25 years. Up until 2002, he was held the
              position of Chairman of Jardine Lloyd Thompson in Asia Pacific for
              more than 10 years. Dennis was also a main board Director of
              Jardine Insurance Brokers PLC which was listed on the London Stock
              Exchange in 1992.{" "}
            </p>

            <p>
              Dennis was Founding Director of Executive Health Solutions Pty Ltd
              which delivers professional and comprehensive health programs for
              corporations and individuals. Dennis is focused on corporate
              governance and risk management within JAG.{" "}
            </p>
          </div>
          <br />

          {/* divider pill */}
          <div className="w-100">
            <div
              style={{
                width: "30px",
                height: "5px",
                backgroundColor: "#e6e6e6",
                borderRadius: "30px",
                margin: "auto auto",
              }}
            />
          </div>
          <br />

          <div>
            <h3 style={{ marginBottom: "0px", paddingBottom: "0px" }}>
              Cameron Reaney
            </h3>
            <span>Director</span>
            <br />
            <br />

            <p>
              Cameron has held executive roles across a range of industries
              including financial services, FMCG and investment banking.{" "}
            </p>

            <p>
              Previous roles include Executive Director of BMS Group, a global
              corporate insurance broker that provides specialist insurance,
              reinsurance and capital markets advisory services, and prior to
              this he was a founding employee and CFO &amp; COO of InterRISK
              Australia Pty Ltd, a major corporate insurance broker established
              in 2004. During his 12 years with this business he lead numerous
              M&amp;A transactions, and forged pivotal strategic relationships
              and commercial outcomes.{" "}
            </p>

            <p>
              Previous roles in FMCG, investment banking and more recently his
              private equity activities, form his foundation of a broad
              knowledge base focused on delivering sustainable growth
              opportunities and supporting improved commercial outcomes to drive
              businesses forward.{" "}
            </p>

            <p>
              In addition to co-founding Finstead Risk Solutions, Cameron is the
              co-founder of Finstead Capital, the commercial broking arm of the
              Finstead Group.{" "}
            </p>
            <p>
              Cameron holds a Bachelor of Commerce, Masters In Accounting and
              CPA.{" "}
            </p>
          </div>
          {/* <div
            className="rc-carousel nav-control-middle3"
            data-loop="true"
            data-items={3}
            data-margin={30}
            data-autoplay="false"
            data-autoplay-timeout={5000}
            data-smart-speed={2000}
            data-dots="false"
            data-nav="false"
            data-nav-speed="false"
            data-r-x-small={1}
            data-r-x-small-nav="false"
            data-r-x-small-dots="false"
            data-r-x-medium={2}
            data-r-x-medium-nav="false"
            data-r-x-medium-dots="false"
            data-r-small={3}
            data-r-small-nav="false"
            data-r-small-dots="false"
            data-r-medium={3}
            data-r-medium-nav="false"
            data-r-medium-dots="false"
            data-r-large={3}
            data-r-large-nav="false"
            data-r-large-dots="false"
          >
            <div className="team-layout1 margin-b-30r bg-light-primary100">
              <div className="item-img">
                <img
                  src={`https://cdn.finstead.com.au/profiles/steven_shirley.jpeg`}
                  alt="team"
                  className="img-fluid width-100"
                />
              </div>
              <div className="item-content">
                <h3 className="title title-medium size-sm color-dark-accent hover-color-accent margin-b-none">
                  <a href="#">James Sinclair</a>
                </h3>
                <div className="sub-title title-regular">Position</div>
              </div>
            </div>

            <div className="team-layout1 margin-b-30r bg-light-primary100">
              <div className="item-img">
                <img
                  src={`https://cdn.finstead.com.au/profiles/steven_shirley.jpeg`}
                  alt="team"
                  className="img-fluid width-100"
                />
              </div>
              <div className="item-content">
                <h3 className="title title-medium size-sm color-dark-accent hover-color-accent margin-b-none">
                  <a href="#">Cameron Reaney</a>
                </h3>
                <div className="sub-title title-regular">Position</div>
              </div>
            </div>

            <div className="team-layout1 margin-b-30r bg-light-primary100">
              <div className="item-img">
                <img
                  src={`https://cdn.finstead.com.au/profiles/steven_shirley.jpeg`}
                  alt="team"
                  className="img-fluid width-100"
                />
              </div>
              <div className="item-content">
                <h3 className="title title-medium size-sm color-dark-accent hover-color-accent margin-b-none">
                  <a href="#">Dennis Guy</a>
                </h3>
                <div className="sub-title title-regular">Position</div>
              </div>
            </div>
          </div> */}
        </div>
      </section>
      {/* <div className="team-showcase-slider-circle-bottom" /> */}
    </>
  );
};

export default TeamShowcaseSlider2;
